// material-ui
import { Link, Container, Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const Footer = () => {
  const siteInfo = useSelector((state) => state.siteInfo);

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '64px' }}>
      <Box sx={{ textAlign: 'center' }}>
        {siteInfo.footer_html ? (
          <div className="custom-footer" dangerouslySetInnerHTML={{ __html: siteInfo.footer_html }}></div>
        ) : (
          <>
            GPT中转ApiKey小白，请看
            <Link href="https://xe-xdl-bucket.s3.us-west-1.amazonaws.com/xe-xdl-bucket/oneApiFile/GPT_apiKey_use_tutorial.pdf" target="_blank">
              《新用户操作指引》
            </Link>
            {' '}
            ，智能{' '}
            <Link href="https://chat.ericgroup.cn" target="_blank">
              LobeChatXE
            </Link>
            {' '}
            超强AI助手！
          </>
        )}
      </Box>
    </Container>
  );
};

export default Footer;
